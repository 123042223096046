import React, { useEffect, useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import Lottie from 'react-lottie-player';
import rocketAnimation from '../../assets/rocket.json';
import { LockClosedIcon } from '@heroicons/react/solid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const FilesPage = () => {
    const [files, setFiles] = useState([]);
    const [token, setToken] = useState('');
    const [timeLeft, setTimeLeft] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const authToken = localStorage.getItem('token');

    const fetchFiles = useCallback(async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.get(`/api/files/personal`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                },
            });
            setFiles(response.data);
        } catch (err) {
            console.error('Error fetching files:', err);
            setError('Failed to load files. Please try again later.');
            toast.error('Failed to load files.');
        } finally {
            setLoading(false);
        }
    }, [authToken]);

    const fetchToken = useCallback(async () => {
        try {
            const response = await axios.get(`/api/files/token`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                },
            });
            const {token: fetchedToken, expiresIn} = response.data;
            setToken(fetchedToken);
            setTimeLeft(expiresIn);
        } catch (err) {
            console.error('Error fetching token:', err);
            toast.error('Failed to fetch token.');
        }
    }, [ authToken]);

    useEffect(() => {
        fetchFiles();
        fetchToken();
    }, [fetchFiles, fetchToken]);

    useEffect(() => {
        const tokenRefreshThreshold = 3 * 60; // 3 minutes in seconds

        const tokenInterval = setInterval(() => {
            if (timeLeft <= tokenRefreshThreshold) {
                fetchToken();
            }
        }, 60 * 1000);

        const countdownInterval = setInterval(() => {
            setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000);

        return () => {
            clearInterval(tokenInterval);
            clearInterval(countdownInterval);
        };
    }, [timeLeft, fetchToken]);

    const handleDownload = async (fileId, fileName) => {
        try {
            const response = await axios.get(`/api/files/download/${fileName}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();

            toast.success(`Downloaded ${fileName} successfully!`);
        } catch (err) {
            console.error('Error downloading file:', err);
            toast.error(`Failed to download ${fileName}.`);
        }
    };

    return (
        <div className="font-sans min-h-screen secure-background flex flex-col items-center px-4 md:px-6">
            <ToastContainer/>
            <motion.div
                className="w-full max-w-6xl mt-12"
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.6, ease: 'easeOut'}}
            >
                {/* Header Section */}
                <div className="flex flex-col items-center mb-8">
                    {/* Rocket Animation */}
                    <div className="relative z-10 flex justify-center">
                        <Lottie
                            loop
                            animationData={rocketAnimation}
                            play
                            style={{
                                width: '150px',
                                height: '150px',
                                marginTop: '20px',
                            }}
                        />
                    </div>
                    <h1 className="text-4xl sm:text-5xl font-bold text-gray-900 mt-4">
                        Secure Downloads
                    </h1>
                </div>

                {/* Token Display Section */}
                <div className="bg-white shadow rounded-lg p-6 mb-8 w-full text-center">
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center justify-center">
                        <LockClosedIcon className="w-6 h-6 text-blue-600 mr-2"/>
                        Your Secure Access Token
                    </h2>
                    <p className="text-gray-700 text-lg font-mono break-all">{token}</p>
                    <p className="text-gray-600 mt-2">
                        Token expires in: <span className="font-semibold">{formatTime(timeLeft)}</span>
                    </p>
                </div>

                {/* Files Table */}
                <div className="bg-white shadow rounded-lg overflow-hidden">
                    {loading ? (
                        <div className="p-6 text-center text-gray-700">Loading files...</div>
                    ) : error ? (
                        <div className="p-6 text-center text-red-500">{error}</div>
                    ) : (
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-blue-100">
                            <tr>
                                <th className="px-6 py-3 text-left text-sm font-medium text-blue-700 uppercase tracking-wider">
                                    File Name
                                </th>
                                <th className="px-6 py-3 text-left text-sm font-medium text-blue-700 uppercase tracking-wider">
                                    File Size
                                </th>
                                <th className="px-6 py-3"></th>
                            </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                            {files.map((file) => (
                                <tr key={file.id} className="hover:bg-blue-50">
                                    <td className="px-6 py-4 whitespace-nowrap text-base text-gray-800">
                                        {file.fileName}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-base text-gray-600">
                                        {formatFileSize(file.fileSize)}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        <button
                                            onClick={() => handleDownload(file.id, file.fileName)}
                                            className="text-blue-600 hover:text-blue-900 bg-blue-100 hover:bg-blue-200 px-4 py-2 rounded transition duration-200"
                                        >
                                            Download Securely
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            {files.length === 0 && (
                                <tr>
                                    <td
                                        colSpan="3"
                                        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                                    >
                                        No files available.
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    )}
                </div>

                {/* Instructions Section */}
                <div className="mt-12 bg-gray-50 p-6 rounded-lg shadow-inner">
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                        Download Files via Terminal
                    </h2>
                    <p className="text-gray-700 mb-4">
                        Use the following <span className="font-mono text-gray-900">curl</span> command to securely
                        download your files:
                    </p>
                    <pre className="bg-gray-800 text-white p-4 rounded overflow-auto">
                        <code>
                            {`curl -H "Authorization: Bearer ${token}" -O https://skyrocketer.com/api/files/download/{fileNameWithoutBrackets}`}
                        </code>
                    </pre>
                    <p className="text-gray-700 mt-4">
                        Replace <span className="font-mono text-gray-900">{`{fileName}`}</span> with the file name.
                    </p>
                    <p className="text-gray-600 mt-2">
                        Note: The token changes every 15 minutes. Ensure you use the current token.
                    </p>
                </div>
            </motion.div>
        </div>
    );

    function formatFileSize(bytes) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    }
}

export default FilesPage;