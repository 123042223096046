import React from "react";
import { Navigate } from "react-router-dom";
import { isTokenValid, isSuperUser } from "../utils/AuthUtils";

const PrivateRoute = ({ children, requiresSuperUser = false }) => {
    if (!isTokenValid()) {
        return <Navigate to="/" replace />;
    }

    if (requiresSuperUser && !isSuperUser()) {
        return <Navigate to="/panel" replace />;
    }

    return children;
};

export default PrivateRoute;