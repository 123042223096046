// src/FilesManagement.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";

const FilesManagement = () => {
    // State variables
    const [files, setFiles] = useState([]);
    const [users, setUsers] = useState([]);
    const [loadingFiles, setLoadingFiles] = useState(true);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [error, setError] = useState("");
    const [isAssignModalOpen, setAssignModalOpen] = useState(false);
    const [isCreateModalOpen, setCreateModalOpen] = useState(false); // New state for Create File modal
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [assigning, setAssigning] = useState(false);
    const [unassigningFileName, setUnassigningFileName] = useState(null);
    const [newFileName, setNewFileName] = useState(""); // New state for file creation
    const [newFileSize, setNewFileSize] = useState("");
    const [newFilePath, setNewFilePath] = useState("");
    const [creatingFile, setCreatingFile] = useState(false); // Loading state for file creation

    // Fetch all files from the backend
    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const token = localStorage.getItem("token");
                if (!token) {
                    setError("Unauthorized. Please log in.");
                    setLoadingFiles(false);
                    return;
                }

                const response = await axios.get(`/api/files`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setFiles(response.data);
                setLoadingFiles(false);
            } catch (err) {
                console.error("Error fetching files:", err);
                setError("Failed to load files. Please try again later.");
                setLoadingFiles(false);
            }
        };

        fetchFiles();
    }, []);

    // Fetch all users from the backend
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const token = localStorage.getItem("token");
                if (!token) {
                    setError("Unauthorized. Please log in.");
                    setLoadingUsers(false);
                    return;
                }

                const response = await axios.get(`/api/users`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setUsers(response.data);
                setLoadingUsers(false);
            } catch (err) {
                console.error("Error fetching users:", err);
                setError("Failed to load users. Please try again later.");
                setLoadingUsers(false);
            }
        };

        fetchUsers();
    }, []);

    // Open the Assign Files modal for a specific user
    const openAssignModal = (user) => {
        setSelectedUser(user);
        setSelectedFiles(user.assignedFiles ? user.assignedFiles.map(file => file.fileName) : []);
        setAssignModalOpen(true);
    };

    // Close the Assign Files modal
    const closeAssignModal = () => {
        setSelectedUser(null);
        setSelectedFiles([]);
        setAssignModalOpen(false);
    };

    // Open the Create File modal
    const openCreateModal = () => {
        setCreateModalOpen(true);
    };

    // Close the Create File modal
    const closeCreateModal = () => {
        setCreateModalOpen(false);
        setNewFileName("");
        setNewFileSize("");
        setNewFilePath("");
    };

    // Handle file selection within the Assign Files modal
    const handleFileSelection = (fileName) => {
        if (selectedFiles.includes(fileName)) {
            setSelectedFiles(selectedFiles.filter(name => name !== fileName));
        } else {
            setSelectedFiles([...selectedFiles, fileName]);
        }
    };

    // Assign selected files to the selected user
    const handleAssignFiles = async (e) => {
        e.preventDefault();
        if (!selectedUser) return;

        setAssigning(true);
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                setError("Unauthorized. Please log in.");
                setAssigning(false);
                return;
            }

            // Send assignment request to the backend
            const response = await axios.put(
                `/api/users/${selectedUser.displayControllerUnit}/assign-files`,
                { fileNames: selectedFiles }, // Use fileNames instead of fileIds
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // Update the users state with the updated user data
            setUsers(users.map(user => user.displayControllerUnit === selectedUser.displayControllerUnit ? response.data : user));

            // Close the modal
            closeAssignModal();
        } catch (err) {
            console.error("Error assigning files:", err);
            setError("Failed to assign files. Please try again.");
        } finally {
            setAssigning(false);
        }
    };

    // Unassign a specific file from a user
    const handleUnassignFile = async (userId, fileName) => {
        if (!window.confirm("Are you sure you want to unassign this file from the user?")) {
            return;
        }

        setUnassigningFileName(fileName);
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                setError("Unauthorized. Please log in.");
                setUnassigningFileName(null);
                return;
            }

            // Send unassignment request to the backend
            const response = await axios.delete(
                `/api/users/${userId}/unassign-file/${encodeURIComponent(fileName)}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // Update the users state with the updated user data
            setUsers(users.map(user => user.id === userId ? response.data : user));
        } catch (err) {
            console.error("Error unassigning file:", err);
            setError("Failed to unassign file. Please try again.");
        } finally {
            setUnassigningFileName(null);
        }
    };

    // Handle input changes for creating a new file
    const handleCreateFileChange = (e) => {
        const { name, value } = e.target;
        if (name === "fileName") setNewFileName(value);
        if (name === "fileSize") setNewFileSize(value);
        if (name === "filePath") setNewFilePath(value);
    };

    // Handle creating a new file
    const handleCreateFile = async (e) => {
        e.preventDefault();
        setCreatingFile(true);
        setError("");

        try {
            const token = localStorage.getItem("token");
            if (!token) {
                setError("Unauthorized. Please log in.");
                setCreatingFile(false);
                return;
            }

            // Validate inputs
            if (!newFileName || !newFileSize || !newFilePath) {
                setError("All fields are required.");
                setCreatingFile(false);
                return;
            }

            // Send creation request to the backend
            const response = await axios.post(
                `/api/files`,
                {
                    fileName: newFileName,
                    fileSize: parseInt(newFileSize, 10),
                    filePath: newFilePath,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // Update the files state with the new file
            setFiles([...files, response.data]);

            // Close the modal
            closeCreateModal();
        } catch (err) {
            console.error("Error creating file:", err);
            if (err.response && err.response.data) {
                setError(err.response.data);
            } else {
                setError("Failed to create file. Please try again.");
            }
        } finally {
            setCreatingFile(false);
        }
    };

    return (
        <div className="bg-gray-100 min-h-screen p-6">
            <div className="max-w-7xl mx-auto">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-semibold">Manage Files Assignment to Users</h2>
                    <button
                        onClick={openCreateModal}
                        className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-500 transition"
                    >
                        Create File
                    </button>
                </div>

                {/* Display error message */}
                {error && (
                    <motion.div
                        className="mb-4 p-4 bg-red-100 text-red-800 rounded-lg shadow"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        {error}
                    </motion.div>
                )}

                {/* Users and Files Table */}
                {(loadingFiles || loadingUsers) ? (
                    <div className="flex justify-center items-center">
                        <svg
                            className="animate-spin h-8 w-8 text-blue-600"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                            ></path>
                        </svg>
                        <span className="ml-2 text-blue-600">Loading...</span>
                    </div>
                ) : (
                    <>
                        <div className="bg-white shadow rounded-lg overflow-hidden mb-6">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Username
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Email
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Assigned Files
                                    </th>
                                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Actions
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                {users.map((user) => (
                                    <tr key={user.id} className="hover:bg-gray-50">
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {user.username}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {user.email}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                            {user.assignedFiles && user.assignedFiles.length > 0
                                                ? user.assignedFiles.map(file => file.fileName).join(", ")
                                                : "No Files Assigned"}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            <button
                                                onClick={() => openAssignModal(user)}
                                                className="text-blue-600 hover:text-blue-900 mr-4"
                                            >
                                                Assign Files
                                            </button>
                                            {/* Display assigned files with option to unassign */}
                                            {user.assignedFiles && user.assignedFiles.map((file) => (
                                                <button
                                                    key={file.fileName}
                                                    onClick={() => handleUnassignFile(user.id, file.fileName)}
                                                    className={`text-red-600 hover:text-red-900 mr-2 ${
                                                        unassigningFileName === file.fileName ? "cursor-not-allowed" : ""
                                                    }`}
                                                    disabled={unassigningFileName === file.fileName}
                                                >
                                                    {unassigningFileName === file.fileName ? "Unassigning..." : `Unassign ${file.fileName}`}
                                                </button>
                                            ))}
                                        </td>
                                    </tr>
                                ))}
                                {users.length === 0 && (
                                    <tr>
                                        <td colSpan="4" className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                                            No users available.
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>

                        {/* All Files Table */}
                        <div className="bg-white shadow rounded-lg overflow-hidden">
                            <h3 className="text-lg font-semibold px-6 py-4 bg-gray-50">All Files</h3>
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        File Name
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Size
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Path
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Assigned Users
                                    </th>
                                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Actions
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                {files.map((file) => (
                                    <tr key={file.fileName} className="hover:bg-gray-50">
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {file.fileName}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {formatFileSize(file.fileSize)}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {file.filePath}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                            {file.assignedUsers && file.assignedUsers.length > 0
                                                ? file.assignedUsers.map(user => user.username).join(", ")
                                                : "No Users Assigned"}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            <a
                                                href={`/api/files/download/${encodeURIComponent(file.fileName)}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-green-600 hover:text-green-900"
                                            >
                                                Download
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                                {files.length === 0 && (
                                    <tr>
                                        <td colSpan="5" className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                                            No files available.
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}

                {/* Assign Files Modal */}
                <AnimatePresence>
                    {isAssignModalOpen && selectedUser && (
                        <motion.div
                            className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            <motion.div
                                className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md"
                                initial={{ scale: 0.8 }}
                                animate={{ scale: 1 }}
                                exit={{ scale: 0.8 }}
                                transition={{ duration: 0.3 }}
                            >
                                <h3 className="text-xl font-semibold mb-4">Assign Files to {selectedUser.username}</h3>
                                <form onSubmit={handleAssignFiles}>
                                    <div className="max-h-60 overflow-y-auto mb-4">
                                        {files.map((file) => (
                                            <div key={file.fileName} className="flex items-center mb-2">
                                                <input
                                                    type="checkbox"
                                                    id={`file-${file.fileName}`}
                                                    checked={selectedFiles.includes(file.fileName)}
                                                    onChange={() => handleFileSelection(file.fileName)}
                                                    className="mr-2"
                                                />
                                                <label htmlFor={`file-${file.fileName}`}>{file.fileName}</label>
                                            </div>
                                        ))}
                                        {files.length === 0 && (
                                            <div className="text-gray-500">No files available.</div>
                                        )}
                                    </div>
                                    <div className="flex justify-end">
                                        <button
                                            type="button"
                                            onClick={closeAssignModal}
                                            className="mr-2 px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-md transition"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            disabled={assigning}
                                            className={`px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500 transition ${
                                                assigning ? "opacity-50 cursor-not-allowed" : ""
                                            }`}
                                        >
                                            {assigning ? "Assigning..." : "Assign"}
                                        </button>
                                    </div>
                                </form>
                            </motion.div>
                        </motion.div>
                    )}
                </AnimatePresence>

                {/* Create File Modal */}
                <AnimatePresence>
                    {isCreateModalOpen && (
                        <motion.div
                            className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            <motion.div
                                className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md"
                                initial={{ scale: 0.8 }}
                                animate={{ scale: 1 }}
                                exit={{ scale: 0.8 }}
                                transition={{ duration: 0.3 }}
                            >
                                <h3 className="text-xl font-semibold mb-4">Create New File</h3>
                                <form onSubmit={handleCreateFile}>
                                    <div className="mb-4">
                                        <label htmlFor="fileName" className="block text-sm font-medium text-gray-700">
                                            File Name
                                        </label>
                                        <input
                                            type="text"
                                            name="fileName"
                                            id="fileName"
                                            value={newFileName}
                                            onChange={handleCreateFileChange}
                                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                                            required
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="fileSize" className="block text-sm font-medium text-gray-700">
                                            File Size (bytes)
                                        </label>
                                        <input
                                            type="number"
                                            name="fileSize"
                                            id="fileSize"
                                            value={newFileSize}
                                            onChange={handleCreateFileChange}
                                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                                            required
                                            min="1"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="filePath" className="block text-sm font-medium text-gray-700">
                                            File Path
                                        </label>
                                        <input
                                            type="text"
                                            name="filePath"
                                            id="filePath"
                                            value={newFilePath}
                                            onChange={handleCreateFileChange}
                                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                                            required
                                        />
                                    </div>
                                    <div className="flex justify-end">
                                        <button
                                            type="button"
                                            onClick={closeCreateModal}
                                            className="mr-2 px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-md transition"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            disabled={creatingFile}
                                            className={`px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-500 transition ${
                                                creatingFile ? "opacity-50 cursor-not-allowed" : ""
                                            }`}
                                        >
                                            {creatingFile ? "Creating..." : "Create"}
                                        </button>
                                    </div>
                                </form>
                            </motion.div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};

// Helper function to format file sizes
function formatFileSize(bytes) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
}

export default FilesManagement;