import React from "react";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie-player";
import { motion } from "framer-motion";
import rocketAnimation from "../assets/rocket.json";

const NotFoundPage = () => {
    const navigate = useNavigate();

    return (
        <motion.div
            className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-blue-50 to-gray-100 px-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            {/* Rocket Animation */}
            <Lottie
                loop
                animationData={rocketAnimation}
                play
                style={{ width: 300, height: 300 }}
            />

            {/* 404 Message */}
            <h1 className="text-4xl sm:text-5xl font-bold text-gray-800 mt-8">
                Oops! Page Not Found
            </h1>
            <p className="mt-4 text-lg sm:text-xl text-gray-600 max-w-md text-center">
                The page you're looking for doesn't exist or has been moved.
            </p>

            {/* Navigation Button */}
            <button
                onClick={() => navigate("/")}
                className="mt-6 px-6 py-3 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-500 transition"
            >
                Go Back Home
            </button>
        </motion.div>
    );
};

export default NotFoundPage;