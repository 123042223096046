// src/UsersManagement.js

import React, { useState, useEffect } from "react";
import axios from "axios";

const UsersManagement = () => {
    const [users, setUsers] = useState([]);
    const [availableProjects, setAvailableProjects] = useState([]);
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [isAssignModalOpen, setAssignModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [newUser, setNewUser] = useState({ username: "", email: "", password: "" });
    const [selectedUser, setSelectedUser] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem("token");

                // Fetch users
                const usersResponse = await axios.get("/api/users", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUsers(usersResponse.data);

                // Fetch projects
                const projectsResponse = await axios.get("/api/projects", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setAvailableProjects(projectsResponse.data);
            } catch (error) {
                console.error("Error fetching data:", error);
                // Handle errors (e.g., show a message, redirect if unauthorized)
            }
        };

        fetchData();
    }, []);

    const handleCreateUser = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem("token");

            // Send POST request to create a new user
            const response = await axios.post(
                "/api/users",
                {
                    username: newUser.username,
                    password: newUser.password,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // Update the users list with the newly created user
            setUsers([...users, response.data]);

            // Reset the form and close the modal
            setNewUser({ username: "", email: "", password: "" });
            setCreateModalOpen(false);
        } catch (error) {
            console.error("Error creating user:", error);
            // Handle errors (e.g., show validation messages)
        }
    };

    const openAssignModal = (user) => {
        setSelectedUser({ ...user });
        setAssignModalOpen(true);
    };

    const handleAssignProjects = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem("token");

            // Send PUT request to update user's projects
            const response = await axios.put(
                `/api/users/${selectedUser.displayControllerUnit}/projects`,
                {
                    projectIds: selectedUser.projects.map((project) => project.displayControllerUnit),
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // Update the users list
            setUsers(users.map((user) => (user.displayControllerUnit === selectedUser.displayControllerUnit ? response.data : user)));

            // Close the modal
            setAssignModalOpen(false);
            setSelectedUser(null);
        } catch (error) {
            console.error("Error assigning projects:", error);
            // Handle errors
        }
    };

    const toggleProjectAssignment = (projectId) => {
        if (selectedUser.projects.some((project) => project.displayControllerUnit === projectId)) {
            setSelectedUser({
                ...selectedUser,
                projects: selectedUser.projects.filter((project) => project.displayControllerUnit !== projectId),
            });
        } else {
            const projectToAdd = availableProjects.find((project) => project.displayControllerUnit === projectId);
            setSelectedUser({
                ...selectedUser,
                projects: [...selectedUser.projects, projectToAdd],
            });
        }
    };

    const openEditModal = (user) => {
        setCurrentUser({ ...user });
        setEditModalOpen(true);
    };

    const handleEditUser = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem("token");

            // Send PUT request to update the user
            const response = await axios.put(
                `/api/users/${currentUser.displayControllerUnit}`,
                {
                    username: currentUser.username,
                    // Include other fields as necessary
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // Update the users list
            setUsers(users.map((user) => (user.displayControllerUnit === currentUser.displayControllerUnit ? response.data : user)));

            // Reset and close the modal
            setEditModalOpen(false);
            setCurrentUser(null);
        } catch (error) {
            console.error("Error updating user:", error);
            // Handle errors
        }
    };

    const handleDeleteUser = async (userId) => {
        if (window.confirm("Are you sure you want to delete this user?")) {
            try {
                const token = localStorage.getItem("token");

                // Send DELETE request to delete the user
                await axios.delete(`/api/users/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                // Remove the user from the list
                setUsers(users.filter((user) => user.displayControllerUnit !== userId));
            } catch (error) {
                console.error("Error deleting user:", error);
                // Handle errors
            }
        }
    };

    const handleCurrentUserChange = (e) => {
        const { name, value } = e.target;
        setCurrentUser({ ...currentUser, [name]: value });
    };

    const handleNewUserChange = (e) => {
        const { name, value } = e.target;
        setNewUser({ ...newUser, [name]: value });
    };

    return (
        <div className="bg-white shadow rounded-lg p-6">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">Manage Users</h2>
                <button
                    onClick={() => setCreateModalOpen(true)}
                    className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-500 transition"
                >
                    Create User
                </button>
            </div>
            {/* Users Table */}
            <table className="min-w-full divide-y divide-gray-200">
                <thead>
                <tr>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                        Username
                    </th>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                        Projects
                    </th>
                    <th className="px-4 py-2"></th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                {users.map((user) => (
                    <tr key={user.displayControllerUnit}>
                        <td className="px-4 py-2 whitespace-nowrap">{user.username}</td>
                        <td className="px-4 py-2 whitespace-nowrap">
                            {user.projects && user.projects.length > 0
                                ? user.projects.map((project) => project.name).join(", ")
                                : "No Projects"}
                        </td>
                        <td className="px-4 py-2 whitespace-nowrap text-right text-sm font-medium">
                            <button
                                onClick={() => openAssignModal(user)}
                                className="text-green-600 hover:text-green-900 mr-2"
                            >
                                Assign Projects
                            </button>
                            <button
                                onClick={() => openEditModal(user)}
                                className="text-blue-600 hover:text-blue-900 mr-2"
                            >
                                Edit
                            </button>
                            <button
                                onClick={() => handleDeleteUser(user.displayControllerUnit)}
                                className="text-red-600 hover:text-red-900"
                            >
                                Delete
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            {/* Create User Modal */}
            {isCreateModalOpen && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
                        <h3 className="text-xl font-semibold mb-4">Create New User</h3>
                        <form onSubmit={handleCreateUser} className="space-y-4">
                            <div>
                                <label className="block text-gray-700">Username</label>
                                <input
                                    type="text"
                                    name="username"
                                    required
                                    value={newUser.username}
                                    onChange={handleNewUserChange}
                                    className="w-full mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700">Temporary Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    required
                                    value={newUser.password}
                                    onChange={handleNewUserChange}
                                    className="w-full mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    onClick={() => setCreateModalOpen(false)}
                                    className="mr-2 px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-md transition"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500 transition"
                                >
                                    Create
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* Edit User Modal */}
            {isEditModalOpen && currentUser && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
                        <h3 className="text-xl font-semibold mb-4">Edit User</h3>
                        <form onSubmit={handleEditUser} className="space-y-4">
                            <div>
                                <label className="block text-gray-700">Username</label>
                                <input
                                    type="text"
                                    name="username"
                                    required
                                    value={currentUser.username}
                                    onChange={handleCurrentUserChange}
                                    className="w-full mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            {/* Optional: Add password reset functionality */}
                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    onClick={() => setEditModalOpen(false)}
                                    className="mr-2 px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-md transition"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500 transition"
                                >
                                    Save Changes
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* Assign Projects Modal */}
            {isAssignModalOpen && selectedUser && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
                        <h3 className="text-xl font-semibold mb-4">
                            Assign Projects to {selectedUser.username}
                        </h3>
                        <form onSubmit={handleAssignProjects} className="space-y-4">
                            <div>
                                {availableProjects.map((project) => (
                                    <div key={project.displayControllerUnit} className="flex items-center mb-2">
                                        <input
                                            type="checkbox"
                                            id={`project-${project.displayControllerUnit}`}
                                            checked={selectedUser.projects.some((p) => p.displayControllerUnit === project.displayControllerUnit)}
                                            onChange={() => toggleProjectAssignment(project.displayControllerUnit)}
                                            className="mr-2"
                                        />
                                        <label htmlFor={`project-${project.displayControllerUnit}`}>{project.name}</label>
                                    </div>
                                ))}
                            </div>
                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    onClick={() => setAssignModalOpen(false)}
                                    className="mr-2 px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-md transition"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-500 transition"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UsersManagement;