import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {FaFolder} from "react-icons/fa";

const WebPanel = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem("token");

                if (!token) {
                    navigate("/", { replace: true });
                    return;
                }

                const response = await axios.get("/api/users/me", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setUserData(response.data);
                setLoading(false);
            } catch (err) {
                console.error(err);
                setError("Failed to fetch user data");
                setLoading(false);
            }
        };

        fetchUserData();
    }, [navigate]);

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <p>Loading...</p>
            </div>
        );
    }

    if (error || !userData) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <p>{error || "An error occurred"}</p>
            </div>
        );
    }

    const { username, balance, projects, assignedFiles } = userData;

    return (
        <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ duration: 0.5 }}
            className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 overflow-hidden"
        >
            <div className="max-w-7xl mx-auto">
                {/* Header Section */}
                <header className="mt-8 px-4 sm:px-6 lg:px-8">
                    <div className="bg-white shadow-md rounded-xl p-8">
                        <h1 className="text-4xl font-bold text-gray-800">Dashboard</h1>
                        <p className="text-gray-600 mt-2">
                            Welcome back, {username}! Here’s an overview of your projects and balance.
                        </p>
                    </div>
                </header>

                {/* Statistics Section */}
                <section className="mt-8 px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {/* Balance Card */}
                        <div className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white p-6 rounded-xl shadow-md">
                            <h3 className="text-lg font-semibold">Your Balance</h3>
                            <p className="text-5xl font-bold mt-4">${balance.toFixed(2)}</p>
                            <p className="mt-4 text-indigo-100">
                                Keep track of your account balance and manage your subscriptions.
                            </p>
                        </div>
                        {/* Active Projects Card */}
                        <div className="bg-gradient-to-r from-blue-500 to-teal-400 text-white p-6 rounded-xl shadow-md">
                            <h3 className="text-lg font-semibold">Active Projects</h3>
                            <p className="text-5xl font-bold mt-4">{projects.length}</p>
                            <p className="mt-4 text-teal-100">
                                You have {projects.length} active project{projects.length !== 1 && 's'}. Explore and manage your projects efficiently.
                            </p>
                        </div>
                        {/* Files Overview Card */}
                        <div className="bg-gradient-to-r from-blue-400 to-indigo-500 text-white p-6 rounded-xl shadow-md flex flex-col justify-between">
                            <div>
                                <h3 className="text-lg font-semibold flex items-center">
                                    <span className="mr-2">
                                        {/* Optional: Add an icon */}
                                        <FaFolder />
                                    </span>
                                    Files Overview
                                </h3>
                                <p className="text-5xl font-bold mt-4">{assignedFiles.length}</p>
                                <p className="mt-4 text-blue-100">
                                    You have {assignedFiles.length} file{assignedFiles.length !== 1 && 's'} stored. Manage your files efficiently!
                                </p>
                            </div>
                            <button
                                onClick={() => navigate("/panel/files")}
                                className="mt-6 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300"
                            >
                                Go to Files
                            </button>
                        </div>
                    </div>
                </section>

                {/* Projects Section */}
                <main className="mt-10 px-4 sm:px-6 lg:px-8">
                    <h2 className="text-2xl font-bold text-gray-800 mb-6">Your Projects</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        {projects.map((project) => (
                            <motion.div
                                key={project.displayControllerUnit}
                                className="bg-white rounded-xl shadow-md hover:shadow-lg transition p-6 relative flex flex-col"
                                whileHover={{ scale: 1.02 }}
                            >
                                {/* Project Image */}
                                <img
                                    src={project.imageLink}
                                    alt={project.name}
                                    className="w-full h-48 object-cover rounded-lg mb-4"
                                />
                                {/* Project Details */}
                                <div className="flex-1">
                                    <h3 className="text-2xl font-bold text-gray-800">{project.name}</h3>
                                    <p className="text-gray-600 mt-2">{project.description}</p>
                                </div>
                                {/* Access Button */}
                                <button
                                    onClick={() => navigate(project.accessLink)}
                                    className="mt-6 px-6 py-3 bg-indigo-600 text-white rounded-lg shadow-md hover:bg-indigo-500 transition"
                                >
                                    Access Project
                                </button>
                            </motion.div>
                        ))}
                    </div>
                </main>
            </div>
        </motion.div>
    );
};

export default WebPanel;