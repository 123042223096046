import React, { useState } from "react";
import Lottie from "react-lottie-player";
import rocketAnimation from "../assets/rocket.json";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ContactUsPage = () => {
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSubmissionStatus(null);

        try {
            const response = await axios.post("/api/contact", {
                name,
                email,
                message,
            });

            if (response.status === 200) {
                setSubmissionStatus("success");
                setName("");
                setEmail("");
                setMessage("");
            } else {
                setSubmissionStatus("error");
            }
        } catch (error) {
            console.error("Error submitting contact form:", error);
            setSubmissionStatus("error");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-50 to-gray-100 flex flex-col items-center justify-center font-sans">
            {/* Back Button */}
            <div className="absolute top-6 left-6">
                <button
                    onClick={() => navigate(-1)}
                    className="text-gray-600 hover:text-blue-600 text-lg font-medium transition"
                >
                    ← Back
                </button>
            </div>

            {/* Main Content */}
            <motion.div
                className="max-w-4xl mx-auto flex flex-col md:flex-row items-center"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
            >
                {/* Left Section - Animation */}
                <div className="w-full md:w-1/2 flex justify-center">
                    <Lottie
                        loop
                        animationData={rocketAnimation}
                        play
                        style={{ width: "300px", height: "300px" }}
                    />
                </div>

                {/* Right Section - Contact Form */}
                <div className="w-full md:w-1/2 mt-8 md:mt-0">
                    <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-6 text-center md:text-left">
                        Get in Touch with Us!
                    </h2>
                    <p className="text-gray-600 text-base md:text-lg mb-6 text-center md:text-left">
                        We're excited to hear about your ideas and how we can help elevate your business.
                    </p>

                    {/* Success/Failure Messages */}
                    {submissionStatus === "success" && (
                        <div className="mb-4 p-4 bg-green-100 text-green-800 rounded-lg">
                            Thank you for reaching out! We'll get back to you soon.
                        </div>
                    )}
                    {submissionStatus === "error" && (
                        <div className="mb-4 p-4 bg-red-100 text-red-800 rounded-lg">
                            Oops! Something went wrong. Please try again later.
                        </div>
                    )}

                    <form className="space-y-4" onSubmit={handleSubmit}>
                        <div>
                            <label className="block text-gray-700">Name</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                className="w-full mt-1 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Your Name"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Email</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="w-full mt-1 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="you@example.com"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Message</label>
                            <textarea
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                                className="w-full mt-1 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                rows="5"
                                placeholder="Tell us about your project or any questions you have..."
                            ></textarea>
                        </div>
                        <div className="flex justify-center md:justify-end">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`px-6 py-3 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-500 transition ${
                                    isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                                }`}
                            >
                                {isSubmitting ? "Sending..." : "Send Message"}
                            </button>
                        </div>
                    </form>
                </div>
            </motion.div>
        </div>
    );
};

export default ContactUsPage;