// src/utils/auth.js
import {jwtDecode} from "jwt-decode";


export const isTokenValid = () => {
    const token = localStorage.getItem("token");
    if (!token) return false;

    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds

        // Check if token is expired
        if (decodedToken.exp && decodedToken.exp < currentTime) {
            localStorage.removeItem("token");
            return false;
        }

        return true;
    } catch (error) {
        // Invalid token format
        localStorage.removeItem("token");
        return false;
    }
};

export const isSuperUser = () => {
    const token = localStorage.getItem("token");
    if (!token) return false;

    try {
        const decodedToken = jwtDecode(token);
        return decodedToken.sub === "superuser"; // Check if username is superuser
    } catch (error) {
        console.error("Superuser validation error:", error);
        return false;
    }
};