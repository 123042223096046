// src/ProjectsManagement.js

import React, { useState, useEffect } from "react";
import axios from "axios";

const ProjectsManagement = () => {
    const [projects, setProjects] = useState([]);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [currentProject, setCurrentProject] = useState(null);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const token = localStorage.getItem("token");

                // Fetch projects from the backend
                const response = await axios.get("/api/projects", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setProjects(response.data);
            } catch (error) {
                console.error("Error fetching projects:", error);
                // Handle errors (e.g., show a message, redirect if unauthorized)
            }
        };

        fetchProjects();
    }, []);

    const openEditModal = (project) => {
        setCurrentProject({ ...project });
        setEditModalOpen(true);
    };

    const handleProjectChange = (e) => {
        const { name, value } = e.target;
        setCurrentProject({ ...currentProject, [name]: value });
    };

    const handleSaveProject = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("token");

        try {
            if (currentProject.displayControllerUnit) {
                // Update existing project (PUT request)
                const response = await axios.put(
                    `/api/projects/${currentProject.displayControllerUnit}`,
                    currentProject,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                // Update the projects list
                setProjects(
                    projects.map((proj) =>
                        proj.displayControllerUnit === currentProject.displayControllerUnit ? response.data : proj
                    )
                );
            } else {
                // Create new project (POST request)
                const response = await axios.post(
                    "/api/projects",
                    currentProject,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                // Add the new project to the list
                setProjects([...projects, response.data]);
            }

            // Close modals and reset current project
            setEditModalOpen(false);
            setCreateModalOpen(false);
            setCurrentProject(null);
        } catch (error) {
            console.error("Error saving project:", error);
            // Handle errors (e.g., display error messages)
        }
    };

    const openCreateModal = () => {
        setCurrentProject({
            displayControllerUnit: null,
            name: "",
            description: "",
            imageLink: "",
            accessLink: "",
        });
        setCreateModalOpen(true);
    };

    const handleDeleteProject = async (projectId) => {
        if (window.confirm("Are you sure you want to delete this project?")) {
            const token = localStorage.getItem("token");

            try {
                await axios.delete(`/api/projects/${projectId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                // Remove the project from the list
                setProjects(projects.filter((project) => project.displayControllerUnit !== projectId));
            } catch (error) {
                console.error("Error deleting project:", error);
                // Handle errors
            }
        }
    };

    return (
        <div className="bg-white shadow rounded-lg p-6">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">Manage Projects</h2>
                <button
                    onClick={openCreateModal}
                    className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-500 transition"
                >
                    Add Project
                </button>
            </div>
            {/* Projects Table */}
            <table className="min-w-full divide-y divide-gray-200">
                <thead>
                <tr>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                        Project Name
                    </th>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                        Description
                    </th>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                        Image Link
                    </th>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                        Access Link
                    </th>
                    <th className="px-4 py-2"></th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                {projects.map((project) => (
                    <tr key={project.displayControllerUnit}>
                        <td className="px-4 py-2 whitespace-nowrap">{project.name}</td>
                        <td className="px-4 py-2 whitespace-nowrap">
                            {project.description}
                        </td>
                        <td className="px-4 py-2 whitespace-nowrap">
                            <a
                                href={project.imageLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-600 hover:underline"
                            >
                                View Image
                            </a>
                        </td>
                        <td className="px-4 py-2 whitespace-nowrap">
                            <a
                                href={project.accessLink}
                                className="text-blue-600 hover:underline"
                            >
                                Access Project
                            </a>
                        </td>
                        <td className="px-4 py-2 whitespace-nowrap text-right text-sm font-medium">
                            <button
                                onClick={() => openEditModal(project)}
                                className="text-blue-600 hover:text-blue-900 mr-2"
                            >
                                Edit
                            </button>
                            <button
                                onClick={() => handleDeleteProject(project.displayControllerUnit)}
                                className="text-red-600 hover:text-red-900"
                            >
                                Delete
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            {/* Create/Edit Project Modal */}
            {(isEditModalOpen || isCreateModalOpen) && currentProject && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
                        <h3 className="text-xl font-semibold mb-4">
                            {currentProject.displayControllerUnit ? "Edit Project" : "Add Project"}
                        </h3>
                        <form onSubmit={handleSaveProject} className="space-y-4">
                            <div>
                                <label className="block text-gray-700">Project Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    required
                                    value={currentProject.name}
                                    onChange={handleProjectChange}
                                    className="w-full mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700">Description</label>
                                <textarea
                                    name="description"
                                    value={currentProject.description}
                                    onChange={handleProjectChange}
                                    className="w-full mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    rows="3"
                                ></textarea>
                            </div>
                            <div>
                                <label className="block text-gray-700">Image Link</label>
                                <input
                                    type="text"
                                    name="imageLink"
                                    value={currentProject.imageLink}
                                    onChange={handleProjectChange}
                                    className="w-full mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700">Access Link</label>
                                <input
                                    type="text"
                                    name="accessLink"
                                    value={currentProject.accessLink}
                                    onChange={handleProjectChange}
                                    className="w-full mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    placeholder="/projects/project-name"
                                />
                            </div>
                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setEditModalOpen(false);
                                        setCreateModalOpen(false);
                                        setCurrentProject(null);
                                    }}
                                    className="mr-2 px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-md transition"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500 transition"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProjectsManagement;