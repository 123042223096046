import React, {useState, useEffect, memo} from "react";
import {Line, Bar} from "react-chartjs-2";
import GaugeChart from "react-gauge-chart";
import {motion, AnimatePresence} from "framer-motion";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import {FiSettings, FiX, FiArrowLeft, FiBarChart2} from "react-icons/fi";
import {Switch} from "@headlessui/react";
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const MemoizedGaugeChart = memo(({threadCount}) => (
    <div className="w-full max-w-xs">
        <GaugeChart
            id="gauge-chart-thread"
            nrOfLevels={20}
            colors={["#22C55E", "#FACC15", "#EF4444"]}
            arcWidth={0.2}
            percent={threadCount / 1000}
            textColor="#D1D5DB"
            needleColor="#D1D5DB"
            animateDuration={900}
            style={{width: "100%"}}
        />
    </div>
));

const ProjectCMC = () => {
    const navigate = useNavigate();

    const [isModalOpen, setModalOpen] = useState(false);

    const serverInstances = [
        { id: '4', name: 'c3-4', ram: '4 GB', vcpus: 2,  bandwidth: '250 Mbps', cost: 0.0453 },
        { id: '8', name: 'c3-8', ram: '8 GB', vcpus: 4, bandwidth: '500 Mbps',  cost: 0.0907 },
        { id: '16', name: 'c3-16', ram: '16 GB', vcpus: 8,  bandwidth: '1 Gbps',  cost: 0.1813 },
        { id: '32', name: 'c3-32', ram: '32 GB', vcpus: 16,  bandwidth: '2 Gbps', cost: 0.3627 },
    ];

    const handleCreateServer = () => {
        if (serverStatus === 'active' || serverStatus === 'creating') {
            toast.warn('A server is already active or being created.');
            return;
        }

        setServerStatus('creating');
        toast.info('Creating server instance...');

        // Simulate server creation delay
        setTimeout(() => {
            setServerStatus('active');
            const instance = serverInstances.find(inst => inst.id === selectedInstance);
            setCostIncurred(instance ? instance.cost : 0);
            toast.success('Server instance created successfully!');
        }, 2000); // 2 seconds delay
    };

    // Handler to destroy server
    const handleDestroyServer = () => {
        if (serverStatus !== 'active') {
            toast.warn('No active server to destroy.');
            return;
        }

        setServerStatus('destroying');
        toast.info('Destroying server instance...');

        // Simulate server destruction delay
        setTimeout(() => {
            setServerStatus('inactive');
            setCostIncurred(0);
            toast.success('Server instance destroyed successfully!');
        }, 2000); // 2 seconds delay
    };


    const handleInstanceChange = (e) => {
        setSelectedInstance(e.target.value);
        setCostIncurred(0);
    };

    const calculateCost = () => {
        const instance = serverInstances.find(inst => inst.id === selectedInstance);
        return instance ? instance.cost : 0;
    };

    const [selectedInstance, setSelectedInstance] = useState(serverInstances[0].id);
    const [serverStatus, setServerStatus] = useState('inactive'); // 'inactive', 'creating', 'active', 'destroying'
    const [costIncurred, setCostIncurred] = useState(0);
    const [showDestroyConfirm, setShowDestroyConfirm] = useState(false);
    const [showMetricsModal, setShowMetricsModal] = useState(false);
    const [cpuUsage, setCpuUsage] = useState(0); // CPU utilization in percentage
    const [ramUsage, setRamUsage] = useState(0); // RAM utilization in percentage

    const settingDescriptions = {
        url: "Enter in the URL as it displays from CMC for your coin",
        ghostInteractions: "How many ghost interactions should there be per day? Format: min amt - max amt",
        accountsPoolPerDay: "How many accounts out of the pool should we utilize per day? Format: [min amt - max amt] OR the word max",
        interactionLength: "Around how many seconds should an interaction be with a coin? Format: min amt - max amt",
        scheduleAccountPerDay: "How many times should an account be scheduled in for an interaction session per day? Format: min amt - max amt",
        delayBetweenCoins: "How long should an account take between viewing one coin and another? Format: min amt - max amt",
        ghostRetainPercent: "What percent of ghost profiles should be retained after usage? Format: [decimal <=1] - [decimal <= 1]",
        topHundredCoinAssignment: "How many coins from the top 100 should be assigned to account profiles? Format: min amt - max amt",
        coinsViewedPerSess: "How many coins should be viewed per account session? Format: min amt - max amt",
        chanceOfYourCoin: "Chance of your coin being viewed in a session? Format: decimal <= 1",
        peakHours: "Hours the application should be most active in? [60% of application flow scheduling] Format: comma seperated list",
        inactiveHours: "Hours the application should be least active in? [10% of application flow scheduling] Format: comma seperated list",
        maintainWatchlist: "How many coins should an account maintain in its watchlist? Format: min amt - max amt",
        ourWatchlistChance: "What's the chance of your coin being one of the coins an account adopts in its watchlist? Format: decimal <= 1",
        flowForRegistered: "Should newly registered accounts wait for the daily smart scheduler to be utilized? Format: boolean value [true/false]",
        registrationMode: "Options: onsite/binance - onsite will register using email/pass on the cmc site, binance will register on binance and then use oAuth to create a cmc account"

    };

    const [moduleStatus, setModuleStatus] = useState({
        Registration: false,
        "Interaction Handler": false,
    });

    useEffect(() => {
        if (showMetricsModal) {
            // Simulate fetching metrics data
            const interval = setInterval(() => {
                setCpuUsage(Math.floor(Math.random() * 100));
                setRamUsage(Math.floor(Math.random() * 100));
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [showMetricsModal]);

    const [metricsModalOpen, setMetricsModalOpen] = useState(false);
    const [threadCount, setThreadCount] = useState(0);
    const [accountNumber, setAccountNumber] = useState(0);
    const [showLogsModal, setShowLogsModal] = useState(false);

    const generateLabels = () => {
        const daysOfWeek = ["Sun", "Mon", "Tues", "Weds", "Thurs", "Fri", "Sat"];
        const today = new Date();
        const labels = [];

        const todayIndex = today.getDay();

        for (let i = 6; i >= 0; i--) {
            const dayIndex = (todayIndex - i + 7) % 7;
            labels.push(daysOfWeek[dayIndex]);
        }

        return labels;
    };

    const [accountData, setAccountData] = useState({
        labels: generateLabels(),
        datasets: [
            {
                label: "Ghost Account Interactions",
                data: [0, 0, 0, 0, 0, 0, 0],
                borderColor: "#3B82F6",
                backgroundColor: "rgba(59, 130, 246, 0.2)",
                fill: true,
                tension: 0.4,
            },
            {
                label: "Regular Account Interactions",
                data: [0, 0, 0, 0, 0, 0, 0],
                borderColor: "#6366F1",
                backgroundColor: "rgba(99, 102, 241, 0.2)",
                fill: true,
                tension: 0.4,
            },
        ],
    });
    const [trendingPositionData, setTrendingPositionData] = useState({
        labels: generateLabels(),
        datasets: [
            {
                label: "Trending Position",
                data: [51, 51, 51, 51, 51, 51, 51],
                backgroundColor: "#2563EB",
                borderColor: "#1D4ED8",
                borderWidth: 1,
                borderRadius: 5,
            },
        ],
    });
    const [newSettings, setNewSettings] = useState({});

    useEffect(() => {
        const token = localStorage.getItem("token");
        axios.get(`/api/projects/cmc/fetch-overall-metrics`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                const data = response.data;

                setModuleStatus({
                    Registration: data.reg ?? false,
                    "Interaction Handler": data.ihmod ?? false,
                });

                setAccountNumber(data.numAccounts || 0);

                setThreadCount(data.ihu || 0);
                if (data.ihdatag && data.ihdata) {
                    setAccountData(prevState => ({
                        ...prevState,
                        datasets: [
                            {
                                ...prevState.datasets[0],
                                data: data.ihdatag || [],
                            },
                            {
                                ...prevState.datasets[1],
                                data: data.ihdata || [],
                            },
                        ],
                    }));
                }
                if (data.trdata) {
                    setTrendingPositionData(prevState => ({
                        ...prevState,
                        datasets: [
                            {
                                ...prevState.datasets[0],
                                data: data.trdata || [],
                            },
                        ],
                    }));
                } else {
                    const errorToastId = "serverOfflineError";

                    toast.error("Error: Server Offline. Please try again later.", {
                        position: "top-right",
                        autoClose: 5000,
                        toastId: errorToastId,
                    });
                }

            })
            .catch(error => {
                console.error('Error fetching metrics:', error);

            });

        axios.get(`/api/projects/cmc/settings`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                const settingsData = response.data;
                setNewSettings(settingsData);
            })
            .catch(error => {
                console.error('Error fetching settings:', error);
            });
    }, []);

    useEffect(() => {
        console.log("ModuleStatus keys after update:", Object.keys(moduleStatus));
    }, [moduleStatus]);

    const handleSettingsSave = () => {
        const token = localStorage.getItem("token");
        axios.put(`/api/projects/cmc/settings`, newSettings, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                setNewSettings(response.data);
                setModalOpen(false);
            })
            .catch(error => {
                console.error('Error updating settings:', error);
            });
    };

    const toggleModuleStatus = (module) => {
        const token = localStorage.getItem("token");
        const newStatus = !moduleStatus[module];

        axios.put(`/api/projects/cmc/module/${module}`, newStatus, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.data) {
                    setModuleStatus(prev => ({
                        ...prev,
                        [module]: newStatus
                    }));
                } else {
                    console.error('Failed to update module status');
                    const errorToastId = "failedToUpdate";

                    toast.error("Error: Failed to update module status", {
                        position: "top-right",
                        autoClose: 5000,
                        toastId: errorToastId,
                    });
                }
            })
            .catch(error => {
                console.error('Error updating module status:', error);
            });
    };

    const chartOptions = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    color: "#D1D5DB",
                    font: {
                        size: 12,
                    },
                    boxWidth: 12,
                    boxHeight: 12,
                },
            },
            tooltip: {
                backgroundColor: "#1F2937",
                titleColor: "#F9FAFB",
                bodyColor: "#F9FAFB",
                cornerRadius: 4,
            },
        },
        scales: {
            x: {
                ticks: {color: "#9CA3AF"},
                grid: {color: "rgba(156, 163, 175, 0.2)"},
            },
            y: {
                ticks: {color: "#9CA3AF"},
                grid: {color: "rgba(156, 163, 175, 0.2)"},
            },
        },
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-gray-100">
            <ToastContainer /> {/* Add this here */}
            <div className="p-6 space-y-6">
                {/* Navigation */}
                <nav className="flex items-center justify-between bg-gray-800 bg-opacity-50 p-4 rounded-xl shadow">
                    <button
                        className="flex items-center text-gray-300 hover:text-white"
                        onClick={() => navigate("/panel")}
                    >
                        <FiArrowLeft className="mr-2" size={20}/>
                        Back to Project List
                    </button>
                    <div className="flex items-center space-x-4">
                        <button
                            className="inline-flex items-center px-4 py-2 bg-gray-700 text-gray-300 rounded-lg shadow hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
                            onClick={() => setModalOpen(true)}
                        >
                            <FiSettings className="mr-2"/>
                            Modify Settings
                        </button>

                        <button
                            className="inline-flex items-center px-4 py-2 bg-gray-700 text-gray-300 rounded-lg shadow hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
                            onClick={() => setMetricsModalOpen(true)}
                        >
                            <FiBarChart2 className="mr-2" size={20} /> {/* Creative icon for metrics */}
                            View Other Metrics
                        </button>
                    </div>
                </nav>

                {/* Header */}
                <header className="bg-gray-800 bg-opacity-50 shadow-sm rounded-xl p-6">
                    <div>
                        <h1 className="text-4xl font-bold text-blue-400">
                            Project Dashboard
                        </h1>
                        <p className="text-gray-400 mt-2">
                            CoinMarketCap: Trending Suite
                        </p>
                    </div>
                </header>

                {/* Charts Section */}
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                    {/* Ghost/Account Interactions */}
                    <motion.div
                        className="lg:col-span-2 bg-gray-800 bg-opacity-50 shadow-sm rounded-xl p-6 flex flex-col h-96"
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.6}}
                    >
                        <h2 className="text-xl font-semibold mb-4 text-blue-400">
                            Ghost/Account Interactions
                        </h2>
                        <div className="flex-1">
                            {accountData ? (
                                <Line data={accountData} options={chartOptions}/>
                            ) : (
                                <p>Loading...</p>
                            )}
                        </div>
                    </motion.div>

                    {/* Trending Position */}
                    <motion.div
                        className="bg-gray-800 bg-opacity-50 shadow-sm rounded-xl p-6 flex flex-col h-96"
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.6, delay: 0.2}}
                    >
                        <h2 className="text-xl font-semibold mb-4 text-blue-400">
                            Trending Position
                        </h2>
                        <div className="flex-1">
                            {trendingPositionData ? (
                                <Bar data={trendingPositionData} options={chartOptions}/>
                            ) : (
                                <p>Loading...</p>
                            )}
                        </div>
                    </motion.div>
                </div>

                {/* Speedometer and Module Control */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    {/* Speedometer */}
                    <div className="bg-gray-800 bg-opacity-50 p-6 rounded-xl shadow-sm flex flex-col items-center">
                        <h2 className="text-xl font-semibold mb-4 text-blue-400">
                            Current Thread Count
                        </h2>
                        <MemoizedGaugeChart threadCount={threadCount}/>
                        <p className="mt-4 text-gray-400">
                            {threadCount} / 1000 threads used
                        </p>


                    </div>

                    {/* Module Control */}
                    <motion.div
                        className="bg-gray-800 bg-opacity-50 p-6 rounded-xl shadow-sm"
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.6}}
                    >
                        <h2 className="text-xl font-semibold mb-6 text-blue-400">
                            Module Control
                        </h2>
                        {Object.keys(moduleStatus).length > 0 ? (
                            Object.keys(moduleStatus).map((module) => (
                                <div
                                    key={module}
                                    className="flex justify-between items-center mb-6"
                                >
                                    <span className="text-gray-300 capitalize">
                                        {module.replace(/([A-Z])/g, " $1")}
                                    </span>
                                    <Switch
                                        checked={moduleStatus[module]}
                                        onChange={() => toggleModuleStatus(module)}
                                        className={`${
                                            moduleStatus[module]
                                                ? "bg-blue-600"
                                                : "bg-gray-600"
                                        } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none`}
                                    >
                                        <span
                                            className={`${
                                                moduleStatus[module]
                                                    ? "translate-x-6"
                                                    : "translate-x-1"
                                            } inline-block w-4 h-4 transform bg-gray-800 rounded-full transition-transform`}
                                        />
                                    </Switch>
                                </div>
                            ))
                        ) : (
                            <p>Loading module statuses...</p>
                        )}
                    </motion.div>
                </div>

                {/* Server Management Section */}
                <div className="bg-gray-800 bg-opacity-50 p-6 rounded-xl shadow-sm">
                    <h2 className="text-xl font-semibold mb-4 text-blue-400">
                        Server Management
                    </h2>

                    {/* Instance Selection */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-300 mb-2">
                            Select Server Instance
                        </label>
                        <select
                            value={selectedInstance}
                            onChange={handleInstanceChange}
                            className="block w-full p-3 bg-white border border-gray-300 rounded-lg shadow-sm
             focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500
             appearance-none cursor-pointer transition duration-200 ease-in-out
             text-gray-700"
                        >
                            <option disabled value="">
                                Select a Server Instance
                            </option>
                            {serverInstances.map((instance) => (
                                <option key={instance.id} value={instance.id}>
                                    {instance.name} - {instance.ram} RAM, {instance.vcpus} vCPUs, {instance.bandwidth}, ${instance.cost} / hr
                                </option>
                            ))}
                        </select>
                    </div>

                    <AnimatePresence>
                        {showLogsModal && (
                            <motion.div
                                className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-70"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <motion.div
                                    className="bg-gray-800 rounded-xl shadow-lg p-6 w-full max-w-2xl relative"
                                    initial={{ scale: 0.8 }}
                                    animate={{ scale: 1 }}
                                    exit={{ scale: 0.8 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <button
                                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-200"
                                        onClick={() => setShowLogsModal(false)}
                                    >
                                        <FiX size={24} />
                                    </button>
                                    <h3 className="text-2xl font-bold text-white mb-4">Last 100 Lines of Console Logs</h3>
                                    <div className="bg-black text-green-400 p-4 rounded-lg overflow-y-auto h-96">
                                        {/* Simulated Logs */}
                                        {Array.from({ length: 100 }, (_, index) => (
                                            <p key={index}>[2024-04-25 12:34:{String(index % 60).padStart(2, '0')}] Console log line {index + 1}</p>
                                        ))}
                                    </div>
                                    <div className="mt-4 flex justify-end">
                                        <button
                                            className="px-4 py-2 bg-blue-600 text-gray-200 rounded-lg hover:bg-blue-500 transition focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            onClick={() => setShowLogsModal(false)}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </motion.div>
                            </motion.div>
                        )}
                    </AnimatePresence>

                    <AnimatePresence>
                        {showMetricsModal && (
                            <motion.div
                                className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-65"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <motion.div
                                    className="bg-gray-800 rounded-xl shadow-lg p-6 w-full max-w-lg relative"
                                    initial={{ scale: 0.8 }}
                                    animate={{ scale: 1 }}
                                    exit={{ scale: 0.8 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <button
                                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-200"
                                        onClick={() => setShowMetricsModal(false)}
                                    >
                                        <FiX size={24} />
                                    </button>
                                    <h3 className="text-2xl font-bold text-white mb-6">Server Metrics</h3>
                                    <div className="space-y-6">
                                        {/* CPU Utilization */}
                                        <div>
                                            <h4 className="text-lg font-semibold text-gray-300 mb-2">CPU Utilization</h4>
                                            <div className="w-full bg-gray-700 rounded-full h-4">
                                                <div
                                                    className="bg-red-500 h-4 rounded-full"
                                                    style={{ width: `${cpuUsage}%` }}
                                                ></div>
                                            </div>
                                            <p className="text-gray-400 mt-1">{cpuUsage}%</p>
                                        </div>
                                        {/* RAM Utilization */}
                                        <div>
                                            <h4 className="text-lg font-semibold text-gray-300 mb-2">RAM Utilization</h4>
                                            <div className="w-full bg-gray-700 rounded-full h-4">
                                                <div
                                                    className="bg-blue-500 h-4 rounded-full"
                                                    style={{ width: `${ramUsage}%` }}
                                                ></div>
                                            </div>
                                            <p className="text-gray-400 mt-1">{ramUsage}%</p>
                                        </div>
                                    </div>
                                    <div className="mt-6 flex justify-end">
                                        <button
                                            className="px-4 py-2 bg-green-600 text-gray-200 rounded-lg hover:bg-green-500 transition focus:outline-none focus:ring-2 focus:ring-green-400"
                                            onClick={() => setShowMetricsModal(false)}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </motion.div>
                            </motion.div>
                        )}
                    </AnimatePresence>

                    {/* Action Buttons */}
                    <div className="flex space-x-4">
                        <button
                            onClick={handleCreateServer}
                            disabled={serverStatus === 'active' || serverStatus === 'creating'}
                            className={`inline-flex items-center px-4 py-2 bg-green-600 text-gray-100 rounded-lg shadow hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-400 ${
                                serverStatus === 'active' || serverStatus === 'creating' ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                        >
                            {serverStatus === 'creating' ? 'Creating...' : 'Create Server'}
                        </button>
                        <button
                            onClick={handleDestroyServer}
                            disabled={serverStatus !== 'active' || serverStatus === 'destroying'}
                            className={`inline-flex items-center px-4 py-2 bg-red-600 text-gray-100 rounded-lg shadow hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-400 ${
                                serverStatus !== 'active' || serverStatus === 'destroying' ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                        >
                            {serverStatus === 'destroying' ? 'Destroying...' : 'Destroy Server'}
                        </button>
                        <button
                            className="px-4 py-2 bg-blue-600 text-gray-200 rounded-lg hover:bg-blue-500 transition focus:outline-none focus:ring-2 focus:ring-blue-400"
                            onClick={() => setShowLogsModal(true)}
                        >
                            View Logs
                        </button>

                        <button
                            className="px-4 py-2 bg-green-600 text-gray-200 rounded-lg hover:bg-green-500 transition focus:outline-none focus:ring-2 focus:ring-green-400"
                            onClick={() => setShowMetricsModal(true)}
                        >
                            View Metrics
                        </button>
                    </div>

                    {/* Status Display */}
                    <div className="mt-4">
                        <p className="text-gray-400">
                            Server Status: <span className={`font-semibold ${serverStatus === 'active' ? 'text-green-400' : serverStatus === 'inactive' ? 'text-red-400' : 'text-yellow-400'}`}>{serverStatus ? serverStatus.charAt(0).toUpperCase() + serverStatus.slice(1) : 'Unknown'}</span>
                        </p>
                        <p className="text-gray-400">
                            Cost Incurred: <span className="font-semibold">${costIncurred.toFixed(4)} per hour</span>
                        </p>
                    </div>
                </div>

                {/* Confirmation Modal for Destroying Server */}
                <AnimatePresence>
                    {showDestroyConfirm && (
                        <motion.div
                            className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-60"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <motion.div
                                className="bg-gray-800 rounded-xl shadow-lg p-6 w-full max-w-md relative"
                                initial={{ scale: 0.8 }}
                                animate={{ scale: 1 }}
                                exit={{ scale: 0.8 }}
                                transition={{ duration: 0.3 }}
                            >
                                <h3 className="text-2xl font-bold text-white mb-4">Confirm Destroy</h3>
                                <p className="text-gray-300 mb-6">Are you sure you want to destroy the server instance?</p>
                                <div className="flex justify-end space-x-4">
                                    <button
                                        className="px-4 py-2 bg-gray-600 text-gray-200 rounded-lg hover:bg-gray-500 transition focus:outline-none focus:ring-2 focus:ring-gray-400"
                                        onClick={() => setShowDestroyConfirm(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="px-4 py-2 bg-red-600 text-gray-200 rounded-lg hover:bg-red-500 transition focus:outline-none focus:ring-2 focus:ring-red-400"
                                        onClick={handleDestroyServer}
                                    >
                                        Destroy
                                    </button>
                                </div>
                            </motion.div>
                        </motion.div>
                    )}
                </AnimatePresence>

                {/* Modal */}
                <AnimatePresence>
                    {metricsModalOpen && (
                        <motion.div
                            className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <motion.div
                                className="bg-gray-800 rounded-xl shadow-lg p-6 w-full max-w-lg relative"
                                initial={{ scale: 0.8 }}
                                animate={{ scale: 1 }}
                                exit={{ scale: 0.8 }}
                                transition={{ duration: 0.3 }}
                            >
                                <button
                                    className="absolute top-4 right-4 text-gray-400 hover:text-gray-200"
                                    onClick={() => setMetricsModalOpen(false)}
                                >
                                    <FiX size={24} />
                                </button>
                                <h3 className="text-2xl font-bold text-white mb-6">Other Metrics</h3>
                                <div className="space-y-4">
                                    <div className="bg-gray-700 p-4 rounded-lg flex justify-between items-center">
                                        <span className="text-gray-300">Accounts Created</span>
                                        <span className="text-gray-300 font-bold text-lg">{accountNumber}</span>
                                    </div>
                                </div>
                                <div className="mt-6 flex justify-end">
                                    <button
                                        className="px-4 py-2 bg-gray-600 text-gray-200 rounded-lg hover:bg-gray-500 transition focus:outline-none focus:ring-2 focus:ring-gray-400"
                                        onClick={() => setMetricsModalOpen(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </motion.div>
                        </motion.div>
                    )}
                </AnimatePresence>

                <AnimatePresence>
                    {isModalOpen && (
                        <motion.div
                            className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                        >
                            <motion.div
                                className="bg-gray-800 rounded-xl shadow-lg p-6 w-full max-w-lg relative"
                                initial={{scale: 0.8}}
                                animate={{scale: 1}}
                                exit={{scale: 0.8}}
                                transition={{duration: 0.3}}
                            >
                                <button
                                    className="absolute top-4 right-4 text-gray-400 hover:text-gray-200"
                                    onClick={() => setModalOpen(false)}
                                >
                                    <FiX size={24}/>
                                </button>
                                <h3 className="text-2xl font-bold text-white mb-6">Modify Settings</h3>

                                {/* Scrollable container */}
                                <div
                                    className="space-y-6 overflow-y-auto max-h-96 pr-4 scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800">
                                    {Object.keys(newSettings).map((key) => (
                                        <div key={key}>
                                            <label className="block text-sm font-medium text-gray-300 mb-2">
                                                {settingDescriptions[key] || key.replace(/_/g, ' ')}
                                            </label>
                                            <input
                                                type="text"
                                                value={newSettings[key]}
                                                onChange={(e) =>
                                                    setNewSettings((prev) => ({
                                                        ...prev,
                                                        [key]: e.target.value,
                                                    }))
                                                }
                                                className="w-full px-4 py-2 bg-gray-700 text-gray-100 border border-gray-600 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                                            />
                                        </div>
                                    ))}
                                </div>

                                <div className="mt-8 flex justify-end space-x-4">
                                    <button
                                        className="px-4 py-2 bg-gray-600 text-gray-200 rounded-lg hover:bg-gray-500 transition focus:outline-none focus:ring-2 focus:ring-gray-400"
                                        onClick={() => setModalOpen(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-500 transition focus:outline-none focus:ring-2 focus:ring-blue-400"
                                        onClick={handleSettingsSave}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </motion.div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default ProjectCMC;