import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import HomePage from "./pages/HomePage";
import ContactUsPage from "./pages/ContactUsPage";
import WebPanel from "./pages/panel/WebPanel";
import ProjectCMC from "./pages/dash/ProjectCMC";
import AdminDashboard from "./admin/AdminDashboard";
import PrivateRoute from "./components/PrivateRoute";
import NotFoundPage from "./pages/NotFoundPage";
import FilesPage from "./pages/dash/FilesPage";

const AnimatedRoutes = () => {
    const location = useLocation();

    return (
        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
                {/* Public Routes */}
                <Route path="/" element={<HomePage />} />
                <Route path="/contact-us" element={<ContactUsPage />} />

                {/* Private Routes */}
                <Route path="/panel/files" element={<PrivateRoute><FilesPage /></PrivateRoute>} />
                <Route path="/panel" element={<PrivateRoute><WebPanel /></PrivateRoute>} />
                <Route path="/project/cmc" element={<PrivateRoute><ProjectCMC /></PrivateRoute>} />
                <Route path="/admin" element={<PrivateRoute requiresSuperUser><AdminDashboard /></PrivateRoute>} />

                {/* 404 Route - Should be the last route */}
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </AnimatePresence>
    );
};

const App = () => {
    return (
        <Router>
            <AnimatedRoutes />
        </Router>
    );
};

export default App;