// src/FormsManagement.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";

const FormsManagement = () => {
    const [formEntries, setFormEntries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [deletingId, setDeletingId] = useState(null);

    // Fetch form entries from the API
    useEffect(() => {
        const fetchFormEntries = async () => {
            try {
                // Get token from localStorage
                const token = localStorage.getItem("token");
                if (!token) {
                    setError("Unauthorized. Please log in.");
                    setLoading(false);
                    return;
                }

                const response = await axios.get("/api/contact", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setFormEntries(response.data);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching form entries:", err);
                setError("Failed to load form entries. Please try again later.");
                setLoading(false);
            }
        };

        fetchFormEntries();
    }, []);

    // Function to handle deletion of a form entry
    const handleDeleteEntry = async (entryId) => {
        if (window.confirm("Are you sure you want to delete this entry?")) {
            try {
                setDeletingId(entryId);

                // Get token from localStorage
                const token = localStorage.getItem("token");
                if (!token) {
                    setError("Unauthorized. Please log in.");
                    setDeletingId(null);
                    return;
                }

                await axios.delete(`/api/contact/${entryId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                // Remove the deleted entry from state
                setFormEntries(formEntries.filter((entry) => entry.id !== entryId));
                setDeletingId(null);
            } catch (err) {
                console.error("Error deleting entry:", err);
                setError("Failed to delete the entry. Please try again.");
                setDeletingId(null);
            }
        }
    };

    return (
        <div className="bg-gray-100 min-h-screen p-6">
            <div className="max-w-6xl mx-auto">
                <h2 className="text-2xl font-semibold mb-6">Manage Form Entries</h2>

                {/* Display error message */}
                {error && (
                    <motion.div
                        className="mb-4 p-4 bg-red-100 text-red-800 rounded-lg shadow"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        {error}
                    </motion.div>
                )}

                {/* Display loading state */}
                {loading ? (
                    <div className="flex justify-center items-center">
                        <svg
                            className="animate-spin h-8 w-8 text-blue-600"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                            ></path>
                        </svg>
                        <span className="ml-2 text-blue-600">Loading...</span>
                    </div>
                ) : (
                    <AnimatePresence>
                        {formEntries.length > 0 ? (
                            <motion.div
                                className="bg-white shadow rounded-lg overflow-hidden"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                            >
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            Email
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            Message
                                        </th>
                                        <th scope="col" className="relative px-6 py-3">
                                            <span className="sr-only">Delete</span>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                    {formEntries.map((entry) => (
                                        <motion.tr
                                            key={entry.id}
                                            initial={{ opacity: 0, y: 10 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: -10 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                {entry.name}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                {entry.email}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                {entry.message}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                <button
                                                    onClick={() => handleDeleteEntry(entry.id)}
                                                    className={`${
                                                        deletingId === entry.id
                                                            ? "text-gray-400 cursor-not-allowed"
                                                            : "text-red-600 hover:text-red-900"
                                                    }`}
                                                    disabled={deletingId === entry.id}
                                                >
                                                    {deletingId === entry.id ? "Deleting..." : "Delete"}
                                                </button>
                                            </td>
                                        </motion.tr>
                                    ))}
                                    </tbody>
                                </table>
                            </motion.div>
                        ) : (
                            <div className="text-center text-gray-500">
                                No form entries found.
                            </div>
                        )}
                    </AnimatePresence>
                )}
            </div>
        </div>
    );
};

export default FormsManagement;