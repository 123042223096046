// AdminDashboard.js
import React, { useState } from "react";
import { motion } from "framer-motion";
import UsersManagement from "./UsersManagement";
import ProjectsManagement from "./ProjectsManagement";
import FormsManagement from "./FormsManagement";
import FilesManagement from "./FilesManagement";

const AdminDashboard = () => {
    const [activeTab, setActiveTab] = useState("users");

    const tabs = [
        { name: "Users", key: "users" },
        { name: "Projects", key: "projects" },
        { name: "Form Entries", key: "forms" },
        { name: "Files", key: "files" }, // New Files tab
    ];

    const renderContent = () => {
        switch (activeTab) {
            case "users":
                return <UsersManagement />;
            case "projects":
                return <ProjectsManagement />;
            case "forms":
                return <FormsManagement />;
            case "files":
                return <FilesManagement />;
            default:
                return null;
        }
    };

    return (
        <div className="min-h-screen bg-gray-100 font-sans">
            {/* Header */}
            <header className="bg-white shadow">
                <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold text-gray-900">Admin Dashboard</h1>
                </div>
            </header>

            {/* Tabs */}
            <div className="flex justify-center bg-white shadow">
                {tabs.map((tab) => (
                    <button
                        key={tab.key}
                        onClick={() => setActiveTab(tab.key)}
                        className={`px-4 py-2 -mb-px text-sm font-medium text-gray-700 border-b-2 ${
                            activeTab === tab.key
                                ? "border-blue-500 text-blue-600"
                                : "border-transparent hover:text-gray-800 hover:border-gray-300"
                        } focus:outline-none`}
                    >
                        {tab.name}
                    </button>
                ))}
            </div>

            {/* Content */}
            <motion.div
                key={activeTab}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.3 }}
                className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8"
            >
                {renderContent()}
            </motion.div>
        </div>
    );
};

export default AdminDashboard;